import React, { Fragment } from 'react';
import {
  DontDo,
  Link,
  List,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  PlatformExceptionList,
  PlatformTable,
  Section,
  SectionSubhead,
} from '../../../../components';
import pageHeroData from '../../../../data/pages/guidelines.yml';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Shadows"
      designCode
      subnav="guidelines">
      <PageHero
        heroData={pageHeroData}
        tierTwo="Layering"
        tierThree="Shadows"
      />

      <PageNavigation links={['Layers', 'Mobile', 'Usage', 'Platform']} />

      <Section title="Layers">
        <Paragraph>
          <Link href="/guidelines/layering/elevation" isDesignCodeLink>
            Layers
          </Link>{' '}
          place interface content “over” other content. While this can obstruct
          content, it increases the available area for additional content
          without leaving the current context. If we apply shadows at different
          levels, we can simulate a three-dimensional stack of layers.
        </Paragraph>
      </Section>

      <Section title="Mobile">
        <Paragraph>
          Shadows differ between platforms. Keep a close eye on how layering and
          elevation affect shadows on both.
        </Paragraph>
        <PlatformExceptionList
          platforms={{
            apple:
              'Shadows should be used subtly and minimally throughout an iOS interface. Depth can display layers in order without using shadows with touch gestures and motion instead.',
            android: (
              <Fragment>
                Layering is closely tied to{' '}
                <Link href="https://material.io/design/environment/elevation.html#depicting-elevation">
                  shadow and elevation
                </Link>{' '}
                for Android design, and should maintain that relationship using
                Uniform shadow values.
              </Fragment>
            ),
          }}
        />
      </Section>

      <Section title="Usage">
        <SectionSubhead>Application</SectionSubhead>
        <List>
          <li>
            The first elevation shadow is meant for elements that are still
            somehow “connected” to a parent element, such as dropdowns or
            popovers.
          </li>
          <li>
            Alerts, like{' '}
            <Link href="/components/notifications/toast" isDesignCodeLink>
              toasts
            </Link>
            , receive the second elevation shadow–they typically don’t block an
            interface, but are still disconnected from any main interface
            element.
          </li>
          <li>
            When blocking an interface entirely with something like a{' '}
            <Link href="/components/dialogs/modal" isDesignCodeLink>
              modal
            </Link>
            , the third elevation shadow should be used.
          </li>
        </List>
        <DontDo
          dontText="mismatch shadows and layers."
          doText="apply the correct shadow to its matching layer."
          imgFilename="shadows-application"
        />

        <SectionSubhead>Layout</SectionSubhead>
        <Paragraph>
          Shadows are not meant to break up content on a page. Our{' '}
          <Link href="/components/themes/level" isDesignCodeLink>
            levels
          </Link>{' '}
          are built to handle that.
        </Paragraph>
        <DontDo
          dontText="put shadows on levels or dividers."
          doText="use levels and accents to break up page content."
          imgFilename="shadows-layout"
        />
      </Section>

      <Section title="Platform">
        <PlatformTable
          platforms={{
            apple: (
              <List>
                <li>
                  {/* eslint-disable max-len */}
                  Follow the{' '}
                  <Link href="https://developer.apple.com/ios/human-interface-guidelines/overview/">
                    HIG
                  </Link>{' '}
                  for individual interface elements.
                  {/* eslint-enable max-len */}
                </li>
              </List>
            ),
            android: (
              <List>
                <li>
                  Follow the{' '}
                  <Link href="https://material.io/guidelines/">
                    Material Guidelines
                  </Link>{' '}
                  for individual interface elements.
                </li>
              </List>
            ),
          }}
        />
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;
